//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-638:_1448,_80,_8680,_5506,_9648,_7968,_4798,_6760;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-638')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-638', "_1448,_80,_8680,_5506,_9648,_7968,_4798,_6760");
        }
      }catch (ex) {
        console.error(ex);
      }